import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import isChromatic from 'chromatic/isChromatic';

const isReviewApp = !!document.querySelector('meta[name="reviewapp"]');
const isDevApp = import.meta.env.DEV;
const isStorybook = import.meta.env.STORYBOOK;

const TRANS_BASE_URL =
  import.meta.env.VITE_TRANS_BASE_URL ?? '/assets/translations';
const TRANS_ENV =
  isStorybook || isReviewApp || isDevApp || isChromatic()
    ? '_latest'
    : '_production';

const TRANS_ENV_URL = `${TRANS_BASE_URL}/${TRANS_ENV}/`;

let currCompanyId = '';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    backend: {
      crossDomain: true,
      loadPath: () =>
        `${TRANS_ENV_URL}{{lng}}${currCompanyId ? `_${currCompanyId}` : ''}`,
    } satisfies HttpBackendOptions,
    interpolation: {
      escapeValue: false,
    },
  });

export function useCompanyTranslation(companyId: string) {
  if (currCompanyId === companyId) return;
  currCompanyId = companyId;
  i18n.reloadResources();
}

export default i18n;
